@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
// @import '~@nebular/auth/styles/all';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  // @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  button:focus {
    outline: none !important;
  }

  nb-layout {
    height: 100%;
    display: block;

    .scrollable-container {
      height: 100%;

      .layout {
        height: 100%;

        .layout-container {
          height: 100%;

          .content {
            height: 100%;

            .columns {
              height: calc(100% - 61px);

              nb-layout-column {
                padding: 20px;
                overflow: auto;
              }
            }
          }
        }
      }
    }
  }
}


dropzone>.dropzone.dz-wrapper .dz-preview .dz-progress {
  display: none !important;
}